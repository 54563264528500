import React, { useEffect, useState } from "react";
import { Box, Typography, Button, useTheme } from "@mui/material";
import Item from "../../components/Item";
import { fetcher2 } from "../../api";

const Favorites = () => {
  const theme = useTheme(); // Gebruik de useTheme hook om toegang te krijgen tot het huidige thema
  const [favoriteItems, setFavoriteItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchFavoriteItems();
  }, []);

  const fetchFavoriteItems = async () => {
    const storedFavorites = JSON.parse(
      localStorage.getItem("favorites") || "[]"
    );

    if (Array.isArray(storedFavorites) && storedFavorites.length > 0) {
      try {
        const data = await Promise.all(
          storedFavorites.map((id) => fetcher2(`items/${id}?populate=image`))
        );

        setFavoriteItems(data.filter(Boolean).map((d) => d.data));
      } catch (error) {
        console.error("Error fetching favorite items:", error);
      }
    } else {
      setFavoriteItems([]);
    }
    setLoading(false);
  };

  const removeFromFavorites = (id) => {
    const updatedFavorites = favoriteItems.filter((item) => item.id !== id);
    localStorage.setItem(
      "favorites",
      JSON.stringify(updatedFavorites.map((item) => item.id))
    );
    setFavoriteItems(updatedFavorites);
  };

  if (loading) {
    return (
      <Box
        width="80%"
        m="80px auto"
        className="shift-on-search"
        sx={{
          color: theme.palette.text.primary, // Zorg ervoor dat de tekstkleur volgens het thema is
        }}
      >
        <Typography variant="h3" align="center">
          Laden...
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      width="80%"
      m="80px auto"
      className="shift-on-search"
      sx={{
        color: theme.palette.text.primary, // Gebruik de tekstkleur van het thema
      }}
    >
      <Typography variant="h3" mb={4}>
        Mijn favorieten
      </Typography>

      <Box display="flex" flexDirection="column" gap="20px">
        {favoriteItems && favoriteItems.length > 0 ? (
          favoriteItems.map((item) => (
            <Box
              key={item.id}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                p: 2,
                borderRadius: 1,
              }}
            >
              <Item item={item} />
              <Button
                variant="contained"
                color="secondary"
                onClick={() => removeFromFavorites(item.id)}
                sx={{
                  backgroundColor: theme.palette.secondary.main, // Gebruik de secundaire kleur van het thema
                  color: theme.palette.secondary.contrastText, // Gebruik de tekstkleur die contrasteert met de secundaire kleur
                  borderRadius: 0,
                  minWidth: "150px",
                  padding: "10px 40px",
                }}
              >
                Verwijderen van favorieten
              </Button>
            </Box>
          ))
        ) : (
          <Typography variant="body1">Geen favorieten gevonden.</Typography>
        )}
      </Box>
    </Box>
  );
};

export default Favorites;
