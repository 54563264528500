// src/scenes/products/Products.jsx

import React from "react";
import ShoppingList2 from "./ShoppingList2";

function Products2() {
  return (
    <div className="products">
      <ShoppingList2 />
    </div>
  );
}

export default Products2;
