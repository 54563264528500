const BASE_URL = "https://api.vuurwerkbruchem.com";
const BASE_URL_METAPI = "https://api.vuurwerkbruchem.com/api";

const API_KEY = process.env.REACT_APP_STRAPI_KEY;
const fetcher = async (endpoint, method = "GET", data = null) => {
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Voeg JWT toe als het in localStorage bestaat
  const jwtToken = localStorage.getItem("jwt");
  if (jwtToken) {
    options.headers["Authorization"] = `Bearer ${jwtToken}`;
  }

  if (data) {
    options.body = JSON.stringify(data);
  }

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, options);
    const jsonData = await response.json();

    if (!response.ok) {
      throw new Error(
        jsonData.message || "Er ging iets mis met de API-aanroep."
      );
    }

    return jsonData;
  } catch (error) {
    throw error;
  }
};
export const fetcher2 = async (endpoint, method = "GET", data = null) => {
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${API_KEY}`, // Gebruik API-key voor authenticatie
    },
  };

  if (data) {
    options.body = JSON.stringify(data);
  }

  try {
    const response = await fetch(`${BASE_URL_METAPI}/${endpoint}`, options);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Er is een fout opgetreden in fetcher2:", error);
    throw error;
  }
};
// Voorbeeld functies
export const getUser = (userId) => fetcher(`/api/users/${userId}`);
export const getItems = () => fetcher("/api/items?populate=image");
export const loginUser = (email, password) =>
  fetcher("post__api_auth_local", "", { identifier: email, password });
export { BASE_URL };
// Voeg hier meer functies toe voor andere endpoints

export const fetchOrdersForUser = (email) =>
  fetcher(`/api/orders?filters[email][$eq]=${email}`);

export const fetchWebshopOrdersForUser = (email) =>
  fetcher(`/api/webshop-orders?filters[email][$eq]=${email}`);
