import React, { useState, useEffect } from "react";

const CookieWall = ({ onSettingsClick }) => {
  const [show, setShow] = useState(false);
  const [height, setHeight] = useState("100px");

  useEffect(() => {
    const accepted = localStorage.getItem("cookiesAccepted");
    if (accepted !== "true") {
      setShow(true);
    }

    const updateHeight = () => {
      if (window.innerWidth <= 768) {
        setHeight("120px");
      } else {
        setHeight("80px");
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    localStorage.setItem("analyticsAccepted", "true"); // Accepteer analytische cookies
    setShow(false);
  };

  const rejectCookies = () => {
    window.location.href = "https://www.google.com";
  };

  return (
    <>
      {show && <div style={{ height: height }} />}
      {show && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            backgroundColor: "var(--cookiewall-bg-color)",
            color: "var(--cookiewall-text-color)",
            padding: "10px",
            textAlign: "center",
            zIndex: 1000,
            height: height,
            boxSizing: "border-box",
            transition: "height 0.3s ease-in-out",
          }}
        >
          <p style={{ margin: 0 }}>
            Deze website gebruikt functionele en analytische cookies voor een
            betere gebruikerservaring. Door op 'Ja' te klikken, gaat u akkoord
            met het gebruik van deze cookies voor verkoopgerelateerde
            activiteiten. Meer informatie vindt u in onze{" "}
            <a
              href="/privacy-policy"
              style={{ color: "var(--cookiewall-link-color)" }}
            >
              privacyverklaring
            </a>
            .
          </p>
          <button
            style={{
              margin: "5px",
              backgroundColor: "var(--cookiewall-button-bg)",
              color: "var(--cookiewall-button-text)",
              border: "none",
              padding: "5px 10px",
              cursor: "pointer",
            }}
            onClick={acceptCookies}
          >
            Ja
          </button>
          <button
            style={{
              margin: "5px",
              backgroundColor: "var(--cookiewall-button-bg)",
              color: "var(--cookiewall-button-text)",
              border: "none",
              padding: "5px 10px",
              cursor: "pointer",
            }}
            onClick={rejectCookies}
          >
            Nee
          </button>
          <button
            onClick={onSettingsClick}
            style={{
              margin: "5px",
              backgroundColor: "var(--cookiewall-button-bg)",
              color: "var(--cookiewall-button-text)",
              border: "none",
              padding: "5px 10px",
              cursor: "pointer",
            }}
          >
            Cookie-instellingen
          </button>
        </div>
      )}
    </>
  );
};

export default CookieWall;
