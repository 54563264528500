import React from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School"; // Nieuw icoon toegevoegd voor het certificaat

const safetyInstructions = [
  {
    icon: "https://api.veiligvieren.nl/wp-content/uploads/2022/12/vv-afsteekinstructies-1.png",
    title: "Lees de Gebruiksaanwijzing",
    text: "Lees voor gebruik aandachtig de gebruiksaanwijzing op ieder artikel.",
  },
  {
    icon: "https://api.veiligvieren.nl/wp-content/uploads/2022/12/vv-afsteekinstructies-2.png",
    title: "Wees Nuchter",
    text: "Zorg dat je nuchter bent als je siervuurwerk afsteekt. Gebruik geen alcohol of verdovende middelen voor het afsteken.",
  },
  {
    icon: "https://api.veiligvieren.nl/wp-content/uploads/2022/12/vv-afsteekinstructies-3.png",
    title: "Draag een Vuurwerkbril",
    text: "Draag altijd een vuurwerkbril tijdens het afsteken. Draag hem óók als je alleen maar kijkt.",
  },
  {
    icon: "https://api.veiligvieren.nl/wp-content/uploads/2022/12/vv-afsteekinstructies-4.png",
    title: "Vermijd Brandbare Kleding",
    text: "Draag nooit nylon kleding of andere brandbare kleding of kleding met een capuchon.",
  },
  {
    icon: "https://api.veiligvieren.nl/wp-content/uploads/2022/12/vv-afsteekinstructies-5.png",
    title: "Houd Omstanders Veilig",
    text: "Let tijdens het afsteken goed op de omgeving en omstanders. Zorg voor voldoende vrije ruimte.",
  },
  {
    icon: "https://api.veiligvieren.nl/wp-content/uploads/2022/12/vv-afsteekinstructies-6.png",
    title: "Gebruik een Aansteeklont",
    text: "Ontsteek siervuurwerk alleen met een aansteeklont, nooit met lucifers, een aansteker of sigaret.",
  },
  {
    icon: "https://api.veiligvieren.nl/wp-content/uploads/2022/12/vv-afsteekinstructies-7.png",
    title: "Steek Aan met Gestrekte Arm",
    text: "Steek siervuurwerk af door de lont met GESTREKTE ARM aan te steken.",
  },
  {
    icon: "https://api.veiligvieren.nl/wp-content/uploads/2022/12/vv-afsteekinstructies-8.png",
    title: "Blijf Uit de Gevarenzone",
    text: "Hang NOOIT met enig deel van je lichaam boven het siervuurwerk.",
  },
  {
    icon: "https://api.veiligvieren.nl/wp-content/uploads/2022/12/vv-afsteekinstructies-9.png",
    title: "Gooi Nooit Vuurwerk",
    text: "Gooi nooit (sier)vuurwerk naar mensen of dieren.",
  },
  {
    icon: "https://api.veiligvieren.nl/wp-content/uploads/2022/12/vv-afsteekinstructies-10.png",
    title: "Houd Afstand",
    text: "Let op de veiligheidsafstand. Zorg dat jij en omstanders niet te dichtbij het siervuurwerk komen.",
  },
  {
    icon: "https://api.veiligvieren.nl/wp-content/uploads/2022/12/vv-afsteekinstructies-11.png",
    title: "Ruim Alles Op",
    text: "Ruim na afloop de resten van het siervuurwerk weer netjes op.",
  },
  {
    icon: "https://api.veiligvieren.nl/wp-content/uploads/2022/12/vv-afsteekinstructies-12.png",
    title: "Steek Op Het Juiste Moment Af",
    text: "Steek siervuurwerk van de categorie F2 alleen af tussen 31 december 18:00 en 1 januari 02:00.",
  },
  {
    icon: "https://api.veiligvieren.nl/wp-content/uploads/2022/12/vv-hoofdlont.png",
    title: "Gebruik het Hoofdlont",
    text: "Steek siervuurwerk altijd eerst aan bij het hoofdlont, vaak te vinden onder deze oranje sticker.",
  },
  {
    icon: "https://api.veiligvieren.nl/wp-content/uploads/2022/12/vv-reservelont.png",
    title: "Gebruik het Reservelont",
    text: "Als het hoofdlont weigert, ga dan niet direct terug naar het artikel! Laat dit minimaal een kwartier onaangeroerd. Daarna kan worden geprobeerd het artikel alsnog aan te steken via het reservelont, vaak gevonden onder deze oranje sticker.",
  },
  {
    icon: "https://api.veiligvieren.nl/wp-content/uploads/2022/12/vv-reservelont.png",
    title: "Gebruik het Reservelont",
    text: "Als het hoofdlont weigert, ga dan niet direct terug naar het artikel! Laat dit minimaal een kwartier onaangeroerd. Daarna kan worden geprobeerd het artikel alsnog aan te steken via het reservelont, vaak gevonden onder deze oranje sticker.",
  },
];

const Veiligheidsinstructies = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      width="80%"
      margin="80px auto"
      padding="20px"
      borderRadius="10px"
      className="shift-on-search"
    >
      <Typography
        variant={isMobile ? "h4" : "h3"}
        textAlign="center"
        fontWeight="bold"
        color="primary"
        marginBottom="20px"
      >
        Veiligheidsinstructies voor het Afsteken van Vuurwerk
      </Typography>

      <Typography
        variant="h6"
        textAlign="center"
        color="textSecondary"
        marginBottom="40px"
      >
        Volg deze richtlijnen om veilig te kunnen genieten van het vuurwerk.
        Voor meer informatie en een afsteekcertificaat, bezoek{" "}
        <a
          href="https://veiligvieren.nl"
          target="_blank"
          rel="noopener noreferrer"
        >
          VeiligVieren.nl
        </a>
        .
      </Typography>

      <Grid container spacing={4}>
        {safetyInstructions.map((instruction, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              elevation={3}
              sx={{
                padding: "20px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "#ffffff",
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <img
                src={instruction.icon}
                alt={`Instruction ${index + 1}`}
                style={{ marginBottom: "15px", width: "80px" }}
              />
              <Typography
                variant="h6"
                fontWeight="bold"
                color="primary"
                gutterBottom
              >
                {instruction.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {instruction.text}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Box marginTop="60px" textAlign="center">
        <Button
          variant="contained"
          color="secondary"
          startIcon={<SchoolIcon />} // Icoon vervangen door een dat certificaat/diploma representeert
          sx={{
            borderRadius: "30px",
            padding: "15px 30px",
            fontSize: "1.2rem",
            fontWeight: "bold",
            backgroundColor: "#e3197e",
            "&:hover": {
              backgroundColor: "#c71585",
            },
          }}
          onClick={() =>
            (window.location.href = "https://cursus.veiligvieren.nl/")
          }
        >
          Haal je Afsteekcertificaat bij VeiligVieren.nl
        </Button>
      </Box>
    </Box>
  );
};

export default Veiligheidsinstructies;
