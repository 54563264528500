import React from "react";

const VerkoopVoorwaarden = () => {
  return (
    <div
      className="shift-on-search"
      style={{ width: "80%", margin: "auto", marginTop: "80px" }}
    >
      <h1>Algemene Voorwaarden</h1>
      <p>
        Vuurwerk Bruchem is een onderdeel van Dingemans Machines. Samen zorgen
        we voor een knallend feest! Lees hieronder onze voorwaarden, zodat alles
        soepel verloopt.
      </p>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h2>Vooruitbetaling</h2>
        <p>
          Bij ons betaal je vooraf, zodat alles klaarstaat op jouw afhaaltijd.
          Mocht er iets misgaan, zoals een faillissement, dan doen we ons
          uiterste best om via de curator een terugbetaling te regelen. Maar
          helaas, we kunnen niets garanderen.
        </p>

        <h2>Aansprakelijkheid</h2>
        <p>
          We willen dat je veilig geniet van ons vuurwerk. Daarom kunnen
          Vuurwerk Bruchem en Dingemans Machines niet verantwoordelijk worden
          gehouden voor schade na levering. Dus let goed op bij het afsteken!
        </p>

        <h2>Annulering en Retour</h2>
        <p>
          Heb je je vuurwerk eenmaal opgehaald? Dan is retourneren niet meer
          mogelijk. Controleer je bestelling goed bij afhalen, want daarna
          vervalt je reclamerecht. Bij annulering van een bestelling wordt 15%
          van het orderbedrag ingehouden als verwerkingskosten.
        </p>

        <h2>Afhalen</h2>
        <p>
          Je kunt je vuurwerk ophalen tijdens het door jou gekozen tijdslot. We
          zorgen ervoor dat alles op tijd klaarstaat, maar eerder afhalen is
          helaas niet mogelijk. Vergeet niet: je mag maximaal 25 kg per persoon
          meenemen. Het naleven van deze regel is jouw verantwoordelijkheid!
        </p>

        <h2>Alternatief Artikel bij Manco</h2>
        <p>
          Als een artikel onverhoopt niet beschikbaar is, krijg je een
          gelijkwaardig of zelfs duurder alternatief. We houden je altijd op de
          hoogte, zodat je weet waar je aan toe bent.
        </p>

        <h2>Spelregels voor Afsteken</h2>
        <p>
          Respect voor mens en dier staat voorop! Vuurwerk afsteken mag alleen
          op 31 december tussen 18:00 en 02:00 uur, behalve voor Categorie 1,
          die mag het hele jaar door. Vergeet niet je vuurwerkbril op te zetten
          en gebruik altijd een aansteeklont. Kijk ook eens naar onze{" "}
          <a href="https://www.vuurwerkbruchem.com/veiligheidsinstructies">
            {" "}
            veiligheidsistructies
          </a>
          . Veiligheid voorop!
        </p>

        <h2>Wijzigingen in Voorwaarden</h2>
        <p>
          We kunnen deze voorwaarden soms aanpassen. We laten het je op tijd
          weten, zodat je niet voor verrassingen komt te staan.
        </p>

        <h2>Privacy</h2>
        <p>
          Jouw privacy is belangrijk voor ons. We gebruiken je gegevens alleen
          zoals de wet voorschrijft. Voor meer informatie, check onze{" "}
          <a href="https://vuurwerkbruchem.com/privacy-policy">
            privacyverklaring
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default VerkoopVoorwaarden;
