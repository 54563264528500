import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Switch,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const CookieSettingsModal = ({ isOpen, onClose }) => {
  const [analyticsAllowed, setAnalyticsAllowed] = useState(false);
  const theme = useTheme(); // Toegang tot het huidige thema

  useEffect(() => {
    if (isOpen) {
      const analyticsAccepted =
        localStorage.getItem("analyticsAccepted") === "true";
      setAnalyticsAllowed(analyticsAccepted);
    }
  }, [isOpen]);

  const handleAnalyticsChange = (e) => {
    const isChecked = e.target.checked;
    setAnalyticsAllowed(isChecked);
    localStorage.setItem("analyticsAccepted", isChecked.toString());
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs">
      <DialogTitle>
        <Typography variant="h6" component="div" style={{ fontWeight: 600 }}>
          Cookie Instellingen
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">Pas je cookievoorkeuren aan.</Typography>
        <Box mt={2}>
          <Typography variant="body1">
            Noodzakelijke cookies (vereist):
          </Typography>
          <Switch checked={true} disabled color="primary" />
          <Typography variant="body2" style={{ marginTop: "5px" }}>
            Deze cookies zijn essentieel voor het functioneren van de website en
            kunnen niet worden uitgeschakeld.
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="body1">
            Toestaan van analytische cookies:
          </Typography>
          <Switch
            checked={analyticsAllowed}
            onChange={handleAnalyticsChange}
            color="primary"
          />
          <Typography variant="body2" style={{ marginTop: "5px" }}>
            Als u analytische cookies uitschakelt, verzamelen we geen gegevens
            over uw gebruik van onze website voor analytische doeleinden.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          color="primary"
          style={{
            backgroundColor: theme.palette.secondary.main,
            color:
              theme.palette.mode === "light"
                ? "#ffffff"
                : theme.palette.text.primary,
          }}
        >
          Sluiten
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookieSettingsModal;
