// src/scenes/products/Products.jsx

import React from "react";
import ShoppingList from "./ShoppingList";

function Products() {
  return (
    <div className="products">
      <ShoppingList />
    </div>
  );
}

export default Products;
