import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { React, useState, useEffect } from "react";
import { BASE_URL } from "../../api";
import { useDispatch } from "react-redux";
import { clearCart } from "../../state";
import { fetcher2 } from "../../api";

const API_BASE_URL = BASE_URL;
// Configuratie-instelling voor startdatum gratis vuurwerk actie
const FREE_ITEMS_START_DATE = new Date(
  process.env.REACT_APP_FREE_ITEMS_START_DATE
);

const handlePrint = () => {
  const printContents = document.getElementById("printArea").innerHTML;

  const iframe = document.createElement("iframe");
  document.body.appendChild(iframe);

  iframe.contentDocument.write(`
    <html>
      <head>
        <title>Print</title>
        <style>
          // Print styles 
        </style>
      </head>
      <body>${printContents}</body>
    </html>
  `);

  iframe.contentDocument.close();
  iframe.contentWindow.print();

  iframe.parentNode.removeChild(iframe);
};

const fetchFreeItems = async () => {
  try {
    const data = await fetcher2("free-items");
    return data.data.map((item) => item.attributes);
  } catch (error) {
    console.error("Error fetching free items:", error);
  }
};

const determineFreeItems = (totalPrice, freeItems, addConditionalItems) => {
  const eligibleFreeItems = freeItems.filter(
    (item) => totalPrice >= item.freeFromPrice
  );
  eligibleFreeItems.sort((a, b) => b.freeFromPrice - a.freeFromPrice);
  const alwaysFreeItems = freeItems.filter((item) => item.freeFromPrice === 0);
  let itemsToAdd = [...alwaysFreeItems];
  if (addConditionalItems && eligibleFreeItems.length > 0) {
    const highestEligibleItem = eligibleFreeItems[0];
    itemsToAdd = [...itemsToAdd, highestEligibleItem];
  }
  return itemsToAdd.filter(Boolean);
};

const calculateEligibleTotalPrice = (products) => {
  return products
    .filter((product) => product.countFreeFireworks)
    .reduce((total, product) => total + product.price * product.count, 0);
};

const Confirmation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [freeItemsAdded, setFreeItemsAdded] = useState(false);
  const [webshopOrder, setWebshopOrder] = useState(null);
  const { webshopOrderNumber } = useParams();
  const navigate = useNavigate();

  // Controleer de paymentStatus en toon de juiste melding
  let paymentMessage = null;

  if (webshopOrder && webshopOrder.attributes.paymentStatus === "open") {
    paymentMessage = (
      <Alert severity="warning">
        <AlertTitle>Betaling in afwachting</AlertTitle>
        Je betaling is nog niet voltooid. Controleer je betaling of neem contact
        met ons op als je hulp nodig hebt.
      </Alert>
    );
  } else if (
    webshopOrder &&
    webshopOrder.attributes.paymentStatus === "failed"
  ) {
    paymentMessage = (
      <Alert severity="error">
        <AlertTitle>Betaling Mislukt</AlertTitle>
        Helaas is je betaling mislukt. Probeer opnieuw te betalen of neem
        contact met ons op.
      </Alert>
    );
  } else if (webshopOrder && webshopOrder.attributes.paymentStatus === "paid") {
    paymentMessage = (
      <Alert severity="success">
        <AlertTitle>Gelukt!</AlertTitle>
        Je betaling is succesvol ontvangen! —
        <strong>Je kunt hieronder je afhaalbewijs afdrukken.</strong>
      </Alert>
    );
  } else if (
    webshopOrder &&
    webshopOrder.attributes.paymentStatus === "completed"
  ) {
    paymentMessage = (
      <Alert severity="success">
        <AlertTitle>Gelukt!</AlertTitle>
        Je bestelling is gemarkeerd als voltooid! —
        <strong>Je kunt hieronder je afhaalbewijs afdrukken.</strong>
      </Alert>
    );
  } else if (
    webshopOrder &&
    webshopOrder.attributes.paymentStatus === "contant"
  ) {
    paymentMessage = (
      <Alert severity="info">
        <AlertTitle>LET OP!</AlertTitle>
        Je hebt gekozen voor betaling in de winkel (contant of pin). Dit dient
        nog te gebeuren in de winkel voordat je de bestelling mee krijgt.
      </Alert>
    );
  }

  useEffect(() => {
    if (webshopOrderNumber && webshopOrderNumber.startsWith("cs_")) {
      navigate("/checkout/success2/" + webshopOrderNumber);
    }
  }, [webshopOrderNumber, navigate]);

  useEffect(() => {
    fetcher2(
      `webshop-orders?filters[webshopOrderNumber][$eq]=${webshopOrderNumber}`
    )
      .then((data) => {
        const order = data.data[0];
        if (!order || freeItemsAdded) return;

        const orderDate = new Date(order.attributes.createdAt);

        fetchFreeItems().then((freeItems) => {
          const eligibleTotalPrice = calculateEligibleTotalPrice(
            order.attributes.products
          );
          const addConditionalItems = orderDate >= FREE_ITEMS_START_DATE;
          const freeProductsToAdd = determineFreeItems(
            eligibleTotalPrice,
            freeItems,
            addConditionalItems
          ).map((item) => ({
            ...item,
            price: 0,
            count: 1,
          }));

          if (freeProductsToAdd.length > 0) {
            const updatedOrder = {
              ...order,
              attributes: {
                ...order.attributes,
                products: [...order.attributes.products, ...freeProductsToAdd],
              },
            };
            setWebshopOrder(updatedOrder);
            setFreeItemsAdded(true);
          } else {
            setWebshopOrder(order);
          }
        });
      })
      .catch((err) => console.error(err));
  }, [webshopOrderNumber, freeItemsAdded]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!webshopOrder) return;
    dispatch(clearCart());
  }, [webshopOrder, dispatch]);

  if (!webshopOrder) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      className="shift-on-search"
      m="80px auto"
      width={isMobile ? "95%" : "80%"}
      height="100%"
      p={isMobile ? 2 : 3}
      bgcolor={theme.palette.background.paper}
      color={theme.palette.text.primary}
    >
      {/* Toon de juiste betalingsstatusboodschap */}
      {paymentMessage}

      {/* Toon de inhoud voor status paid/completed/contant */}
      {webshopOrder && (
        <>
          <Box
            id="printArea"
            m="20px auto"
            width={isMobile ? "100%" : "210mm"}
            height={isMobile ? "auto" : "297mm"}
            border={isMobile ? "none" : "1px solid black"}
            p={isMobile ? 2 : 3}
            bgcolor={theme.palette.background.paper}
            color={theme.palette.text.primary}
          >
            <Box display="flex" justifyContent="flex-start">
              <span className="MuiTypography-root MuiTypography-h3title vuurwerk-bruchem-text css-w65vrb-MuiTypography-root">
                <b>VUURWERK BRUCHEM</b>
              </span>
            </Box>

            <Typography align="left" gutterBottom>
              Dorpsstraat 20
            </Typography>
            <Typography align="left" gutterBottom>
              {" "}
              5314 AE Bruchem
            </Typography>
            <Typography align="left" gutterBottom>
              0418 700 511
            </Typography>
            <Typography align="left" gutterBottom>
              VUURWERKBRUCHEM.NL
            </Typography>

            <hr style={{ margin: "20px 0" }} />

            <Box my={3}>
              <Typography>
                <strong>Bestelnummer:</strong> {webshopOrder.id}
              </Typography>
              <Typography>
                <strong>Naam:</strong> {webshopOrder.attributes.userName}
              </Typography>
              <Typography>
                <strong>Email:</strong> {webshopOrder.attributes.email}
              </Typography>
              <Typography>
                <strong>Telefoon:</strong> {webshopOrder.attributes.phoneNumber}
              </Typography>
            </Box>

            <Typography variant="h6" gutterBottom>
              Afhaalgegevens
            </Typography>

            <Typography>
              <strong>Datum:</strong> {webshopOrder.attributes.pickupDate}
            </Typography>
            <Typography>
              <strong>Tijd:</strong> {webshopOrder.attributes.pickupTime}
            </Typography>
            <Typography>
              <strong>Status:</strong> {webshopOrder.attributes.paymentStatus}
            </Typography>

            <Box mt={3}>
              <Typography variant="h6" gutterBottom>
                Producten
              </Typography>

              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                      Art. Nummer
                    </th>
                    <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                      Naam
                    </th>
                    <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                      Prijs
                    </th>
                    <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                      Aantal
                    </th>
                    <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                      Subtotaal
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {webshopOrder.attributes.products.map((product) => (
                    <tr key={product.id}>
                      <td style={{ paddingBottom: "8px" }}>
                        {product.artNumber}
                      </td>
                      <td style={{ paddingBottom: "8px" }}>{product.name}</td>
                      <td style={{ paddingBottom: "8px" }}>
                        €{product.price ? product.price.toFixed(2) : "0.00"}
                      </td>
                      <td style={{ paddingBottom: "8px" }}>{product.count}</td>
                      <td style={{ paddingBottom: "8px" }}>
                        €{(product.price * product.count).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="4" style={{ paddingTop: "10px" }}>
                      <strong>Totaal:</strong>
                    </td>
                    <td style={{ paddingTop: "10px" }}>
                      <strong>
                        €
                        {webshopOrder.attributes.products
                          .reduce(
                            (acc, product) =>
                              acc + product.price * product.count,
                            0
                          )
                          .toFixed(2)}
                      </strong>
                    </td>
                  </tr>
                  {webshopOrder.attributes.totalPrice.toFixed(2) !==
                    webshopOrder.attributes.products
                      .reduce(
                        (acc, product) => acc + product.price * product.count,
                        0
                      )
                      .toFixed(2) && (
                    <tr>
                      <td colSpan="4" style={{ paddingTop: "10px" }}>
                        <strong>Totaal na toegepaste kortingscode:</strong>
                      </td>
                      <td style={{ paddingTop: "10px" }}>
                        <strong>
                          €{webshopOrder.attributes.totalPrice.toFixed(2)}
                        </strong>
                      </td>
                    </tr>
                  )}
                </tfoot>
              </table>
            </Box>

            <Typography align="center" gutterBottom>
              Dank voor uw bestelling bij Vuurwerk Bruchem!
            </Typography>
          </Box>

          <Button variant="contained" color="primary" onClick={handlePrint}>
            Print Afhaalbewijs
          </Button>
        </>
      )}
    </Box>
  );
};

export default Confirmation;
