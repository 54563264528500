import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  IconButton,
  Box,
  Typography,
  Badge,
  Menu,
  MenuItem,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import PersonOutline from "@mui/icons-material/PersonOutline";
import CheckCircle from "@mui/icons-material/CheckCircle";
import ShoppingBagOutlined from "@mui/icons-material/ShoppingBagOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { removeFromCart, setIsCartOpen, setCount } from "../../state";
import { useAuth } from "../../AuthContext";
import DarkModeIcon from "../../assets/svg/dark_mode_24dp_E8EAED_FILL1_wght400_GRAD0_opsz24.svg";
import LightModeIcon from "../../assets/svg/light_mode_24dp_E8EAED_FILL1_wght400_GRAD0_opsz24.svg";
import { shades } from "../../theme";
import { BASE_URL } from "../../api";

function Navbar({ onAuthClick, onProfileClick, isDarkMode, setIsDarkMode }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const { isLoggedIn } = useAuth();
  const { logout } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchIconRef = useRef(null);
  const searchBoxRef = useRef(null);
  const menuRef = useRef(null);
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutAndRedirect = () => {
    setTimeout(() => {
      logout();
      navigate("/");
    }, 500);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleSearchSubmit = () => {
    navigate(`/search?query=${searchQuery}`);
  };

  useEffect(() => {
    if (anchorEl) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [anchorEl]);

  useEffect(() => {
    if (isSearchOpen) {
      document.body.classList.add("search-open-margin");
    } else {
      document.body.classList.remove("search-open-margin");
    }
  }, [isSearchOpen]);

  useEffect(() => {
    setIsSearchOpen(false);
  }, [navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Dark mode logica toevoegen
  useEffect(() => {
    document.body.classList.toggle("darkmode", isDarkMode);
  }, [isDarkMode]);

  const removeOutOfStockItemsFromCart = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/items`);
      const itemsData = await response.json();

      for (let cartItem of cart) {
        const item = itemsData.data.find(
          (apiItem) => apiItem.id === cartItem.id
        );

        if (item && item.attributes.stockQuantity !== null) {
          if (item.attributes.stockQuantity === 0) {
            dispatch(removeFromCart({ id: cartItem.id }));
          } else {
            if (
              item.attributes.maxPerOrder !== null &&
              cartItem.count > item.attributes.maxPerOrder
            ) {
              dispatch(
                setCount({
                  id: cartItem.id,
                  count: item.attributes.maxPerOrder,
                })
              );
            } else if (cartItem.count > item.attributes.stockQuantity) {
              dispatch(
                setCount({
                  id: cartItem.id,
                  count: item.attributes.stockQuantity,
                })
              );
            }
          }
        }
      }
    } catch (error) {
      console.error("Error processing stock information:", error);
    }
  };

  const handleCartClick = () => {
    removeOutOfStockItemsFromCart();
    dispatch(setIsCartOpen({}));
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      height="60px"
      backgroundColor={theme.palette.background.default}
      color={theme.palette.text.primary}
      position="fixed"
      top="0"
      left="0"
      zIndex="1"
    >
      <Box
        width="80%"
        margin="auto"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          alignItems="center"
          onClick={() => navigate("/")}
          sx={{ "&:hover": { cursor: "pointer" } }}
        >
          <Link to="/">
            <HomeOutlinedIcon
              className="vuurwerk-bruchem-icon"
              sx={{
                fontSize: "22px",
                verticalAlign: "middle",
                color: theme.palette.text.primary,
              }}
              aria-label="Home"
            />
          </Link>
          <Typography
            variant="h3title"
            color={shades.secondary[400]}
            className="vuurwerk-bruchem-text"
            sx={{ ml: 0, display: { xs: "none", md: "block" } }}
          >
            VUURWERK BRUCHEM
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" columnGap="10px">
          <IconButton
            onClick={() => setIsDarkMode(!isDarkMode)}
            sx={{
              color: theme.palette.text.primary,
              padding: 0,
              margin: 0,
              height: "40px",
              width: "40px",
            }}
            aria-label="Toggle Dark Mode"
          >
            <img
              src={isDarkMode ? LightModeIcon : DarkModeIcon}
              alt="Toggle Dark Mode"
              style={{
                width: "20px", // Verklein het icoon
                height: "20px", // Verklein het icoon
                filter: isDarkMode ? "invert(1)" : "none", // Kleur omkeren in dark mode
              }}
            />
          </IconButton>

          <IconButton
            ref={searchIconRef}
            sx={{ color: theme.palette.text.primary }}
            onClick={toggleSearch}
            aria-label="Search"
          >
            <SearchOutlined />
          </IconButton>
          <IconButton
            onClick={() => navigate("/favorites")}
            sx={{ color: theme.palette.text.primary }}
            aria-label="Favorites"
          >
            <FavoriteBorder />
          </IconButton>
          {isLoggedIn ? (
            <>
              <IconButton
                sx={{ color: theme.palette.text.primary, position: "relative" }}
                onClick={handleClick}
                aria-label="Profile"
              >
                <PersonOutline />
                <CheckCircle
                  style={{ color: shades.green }}
                  fontSize="small"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: 12,
                    height: 12,
                  }}
                  aria-hidden="true"
                />
              </IconButton>
              <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    position: "absolute",
                    top: "100%",
                    left: "0",
                    zIndex: 1000,
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  },
                }}
                ref={menuRef}
              >
                <MenuItem
                  onClick={() => {
                    navigate("/orders");
                    handleClose();
                  }}
                >
                  Mijn bestellingen
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/profile");
                    handleClose();
                  }}
                >
                  Mijn gegevens
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    logoutAndRedirect();
                    handleClose();
                  }}
                >
                  Uitloggen
                </MenuItem>
              </Menu>
            </>
          ) : (
            <IconButton
              sx={{ color: theme.palette.text.primary, position: "relative" }}
              onClick={onAuthClick}
              aria-label="Login"
            >
              <PersonOutline />
            </IconButton>
          )}
          <Badge
            badgeContent={cart.length}
            color="secondary"
            invisible={cart.length === 0}
            sx={{
              "& .MuiBadge-badge": {
                right: 5,
                top: 5,
                padding: "0 4px",
                height: "14px",
                minWidth: "13px",
                backgroundColor: shades.secondary[400],
                color:
                  theme.palette.mode === "light"
                    ? "#FFFFFF"
                    : theme.palette.text.primary,
              },
            }}
          >
            <IconButton
              onClick={handleCartClick}
              sx={{ color: theme.palette.text.primary }}
              aria-label="Cart"
            >
              <ShoppingBagOutlined />
            </IconButton>
          </Badge>
        </Box>
      </Box>
      {isSearchOpen && (
        <Box ref={searchBoxRef} className="customSearchBox">
          <TextField
            className="customSearchField"
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearchSubmit();
              }
            }}
            autoFocus
            sx={{
              backgroundColor: theme.palette.background.paper, // Zorgt ervoor dat de achtergrondkleur overeenkomt met het thema
              color: theme.palette.text.primary, // Zorgt ervoor dat de tekstkleur overeenkomt met het thema
              "& .MuiInputBase-input": {
                color: theme.palette.text.primary, // Zorgt ervoor dat de invoer tekstkleur overeenkomt met het thema
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.divider, // Zorgt ervoor dat de randkleur overeenkomt met het thema
              },
            }}
          />
          <IconButton
            onClick={handleSearchSubmit}
            sx={{ color: theme.palette.text.primary }}
            aria-label="Submit Search"
          >
            <SearchOutlined />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

export default Navbar;
