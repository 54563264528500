import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useAuth } from "../AuthContext";
import { BASE_URL } from "../api";
import ReCAPTCHA from "react-google-recaptcha";
import validator from "validator";
import axios from "axios";
const validateFields = async (
  email,
  phoneNumber,
  streetName,
  houseNumber,
  postalCode,
  firstName,
  lastName
) => {
  let isValid = true;

  if (
    firstName.length < 2 ||
    !validator.isAlpha(firstName.replace(/\s+/g, ""))
  ) {
    isValid = false;
    alert("Ongeldige voornaam");
  }

  if (lastName.length < 2 || !validator.isAlpha(lastName.replace(/\s+/g, ""))) {
    isValid = false;
    alert("Ongeldige achternaam");
  }

  if (!validator.isNumeric(phoneNumber)) {
    isValid = false;
    alert("Ongeldig telefoonnummer");
  }

  if (!validator.isAlpha(streetName.replace(/\s+/g, ""))) {
    isValid = false;
    alert("Ongeldige straatnaam");
  }

  if (!validator.isNumeric(houseNumber)) {
    isValid = false;
    alert("Ongeldig huisnummer");
  }
  const headers = {
    Authorization: "Bearer 7d5340e4-d9d8-4b71-82e2-4bf3a7d311af",
  };
  const formattedPostcode = postalCode.replace(/\s+/g, "").toUpperCase();
  if (!/^\d{4}[A-Z]{2}$/.test(formattedPostcode)) {
    isValid = false;
    alert("Ongeldige postcode");
  }

  if (!validator.isEmail(email)) {
    isValid = false;
    alert("Ongeldig e-mailadres");
  }

  if (isValid) {
    try {
      const response = await axios.get(
        `https://postcode.tech/api/v1/postcode?postcode=${formattedPostcode}&number=${houseNumber}`,
        { headers }
      );
      if (!response.data) {
        alert("Ongeldig adres");
        isValid = false;
      }
    } catch (error) {
      alert("Fout bij het valideren van het adres");
      isValid = false;
    }
  }

  return isValid;
};

const AuthModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const [streetName, setStreetName] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState(null);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const { login, registerUser } = useAuth();

  async function handleLogin(event) {
    event.preventDefault();
    try {
      const response = await fetch(`${BASE_URL}/api/auth/local`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          identifier: email,
          password: password,
        }),
      });
      const data = await response.json();
      if (data.jwt) {
        localStorage.setItem("jwt", data.jwt);
        login(data.user, data.jwt);
        onClose();
      } else {
        setError("Aanmelding mislukt. Controleer uw gegevens.");
      }
    } catch (error) {
      setError("Er is een fout opgetreden. Probeer het opnieuw.");
    }
  }

  const handleRegister = async (e) => {
    e.preventDefault();
    if (
      !(await validateFields(
        email,
        phoneNumber,
        streetName,
        houseNumber,
        postalCode,
        firstName,
        lastName
      ))
    )
      return;
    try {
      const user = await registerUser(
        email,
        email,
        password,
        firstName,
        lastName,
        streetName,
        houseNumber,
        postalCode,
        city,
        phoneNumber
      );
      if (user) {
        setRegistrationSuccess(true);
        onClose();
        setTimeout(() => {
          window.location.href = "/profile";
        }, 2000);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs">
      <DialogTitle>
        <Typography variant="h6" component="div" style={{ fontWeight: 600 }}>
          {isRegistering ? "Registreren" : "Inloggen"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {error && <Typography color="error">{error}</Typography>}
        {registrationSuccess && (
          <Typography color="primary">
            console.log("Checking for registration success message rendering,
            value:", registrationSuccess); Registratie gelukt! U bent nu
            ingelogd.
          </Typography>
        )}
        {isRegistering ? (
          <form onSubmit={handleRegister}>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              type="email"
              placeholder="E-mailadres"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              type="password"
              placeholder="Wachtwoord"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="Voornaam"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="Achternaam"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="Straatnaam"
              value={streetName}
              onChange={(e) => setStreetName(e.target.value)}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="Huisnummer"
              value={houseNumber}
              onChange={(e) => setHouseNumber(e.target.value)}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="Postcode"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="Woonplaats"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="Telefoonnummer"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />

            <ReCAPTCHA
              sitekey="6LfPUFooAAAAAB-v1ptDt4wGWoiaEvyNon9ct1vk"
              size="invisible"
              onChange={(value) => setRecaptchaValue(value)}
            />

            <DialogActions>
              <Button variant="contained" color="primary" type="submit">
                Registreren
              </Button>
            </DialogActions>
          </form>
        ) : (
          <form onSubmit={handleLogin}>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              type="email"
              placeholder="E-mailadres"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              type="password"
              placeholder="Wachtwoord"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            <ReCAPTCHA
              sitekey="6LfPUFooAAAAAB-v1ptDt4wGWoiaEvyNon9ct1vk"
              size="invisible"
              onChange={(value) => setRecaptchaValue(value)}
            />

            <DialogActions>
              <Button variant="contained" color="primary" type="submit">
                Inloggen
              </Button>
            </DialogActions>
          </form>
        )}
      </DialogContent>
      {isRegistering ? (
        <Typography variant="body2" align="center" style={{ margin: "10px 0" }}>
          Al een account?{" "}
          <Button color="primary" onClick={() => setIsRegistering(false)}>
            Inloggen
          </Button>
        </Typography>
      ) : (
        <Typography variant="body2" align="center" style={{ margin: "10px 0" }}>
          Nog geen account?{" "}
          <Button color="primary" onClick={() => setIsRegistering(true)}>
            Registreren
          </Button>
        </Typography>
      )}
    </Dialog>
  );
};

export default AuthModal;
