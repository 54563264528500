import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  TextField,
  Typography,
  MenuItem,
  InputAdornment,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Divider,
  IconButton,
  Grid,
  useMediaQuery,
} from "@mui/material";
import styled from "@emotion/styled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { BASE_URL, fetcher2 } from "../../api"; // Voor Strapi API
const CODE_API_URL = "https://code.vuurwerkbruchem.com/api"; // Hardcoded URL voor de code-server

const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Checkout2 = () => {
  const { userData } = useAuth();
  const [discountCode, setDiscountCode] = useState("");
  const [discount, setDiscount] = useState({ type: "", value: 0 });
  const [paymentMethod, setPaymentMethod] = useState("online");
  const [cashCode, setCashCode] = useState("");
  const [userInputCashCode, setUserInputCashCode] = useState("");
  const [cashAccepted, setCashAccepted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notes, setNotes] = useState("");
  const [firstName, setFirstName] = useState(userData?.firstName || "");
  const [lastName, setLastName] = useState(userData?.lastName || "");
  const [email, setEmail] = useState(userData?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(userData?.phoneNumber || "");
  const [streetName, setStreetName] = useState(userData?.streetName || "");
  const [houseNumber, setHouseNumber] = useState(userData?.houseNumber || "");
  const [postalCode, setPostalCode] = useState(userData?.postalCode || "");
  const [houseNumberSuffix, setHouseNumberSuffix] = useState(
    userData?.houseNumberSuffix || ""
  );
  const [city, setCity] = useState(userData?.city || "");
  const [pickupDate, setPickupDate] = useState("");
  const [pickupTime, setPickupTime] = useState("");
  const [pickupMoments, setPickupMoments] = useState([]);
  const [tosAgreed, setTosAgreed] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [originalTotalPrice, setOriginalTotalPrice] = useState(0);
  const cart = useSelector((state) => state.cart.cart);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [showOrderDetails, setShowOrderDetails] = useState(!isMobile);
  const [errors, setErrors] = useState({});
  const [showDiscountCodeInput, setShowDiscountCodeInput] = useState(false);
  const [token, setToken] = useState("");

  useEffect(() => {
    const loadPickupMoments = async () => {
      try {
        const data = await fetcher2(
          "pickup-moments?populate=excludedArtclesFromTimeSlots"
        );
        setPickupMoments(data.data);
      } catch (error) {
        console.error("Error fetching pickup moments", error);
      }
    };
    loadPickupMoments();
  }, []);

  useEffect(() => {
    const login = async () => {
      try {
        console.log("Inloggen gestart...");

        const response = await fetch(
          "https://code.vuurwerkbruchem.com/api/token",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              password: process.env.REACT_APP_TOKEN_PASSWORD,
            }),
          }
        );

        const data = await response.json();
        console.log("Inlog response:", data);
        console.log("Response status:", response.status);

        if (response.ok) {
          setToken(data.token);
          console.log("Token succesvol opgehaald:", data.token);
        } else {
          console.error(
            "Fout bij inloggen, status:",
            response.status,
            "message:",
            data.message
          );
        }
      } catch (error) {
        console.error("Fout bij het inloggen", error);
      }
    };

    if (paymentMethod === "contant" && !token) {
      console.log("Start inlogpoging voor contante betaling...");
      login();
    }
  }, [paymentMethod]); // Haal 'token' weg uit de dependencies

  useEffect(() => {
    const fetchCashCode = async () => {
      try {
        console.log("Start ophalen van geheime code...");

        if (!token) {
          console.log(
            "Geen token beschikbaar, geheime code wordt niet opgehaald"
          );
          return;
        }

        const response = await fetch(
          "https://code.vuurwerkbruchem.com/api/code",
          {
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        console.log("Code ophaal response:", response);

        if (response.ok) {
          const data = await response.json();
          setCashCode(data.code);
          console.log("Geheime code succesvol opgehaald:", data.code);
        } else {
          console.error("Fout bij het ophalen van de code", response.status);
        }
      } catch (error) {
        console.error(
          "Er is een fout opgetreden bij het ophalen van de code",
          error
        );
      }
    };

    if (token) {
      fetchCashCode();
    }
  }, [token]); // Deze effect loopt wanneer de token wordt ingesteld

  const validate = () => {
    let tempErrors = {};

    console.log("Start validatie...");

    tempErrors.firstName = firstName ? "" : "Voornaam is verplicht";
    tempErrors.lastName = lastName ? "" : "Achternaam is verplicht";
    tempErrors.email = email
      ? /\S+@\S+\.\S+/.test(email)
        ? ""
        : "Ongeldig e-mailadres"
      : "E-mail is verplicht";
    tempErrors.phoneNumber = phoneNumber
      ? /^\+?[0-9]+$/.test(phoneNumber)
        ? ""
        : "Ongeldig telefoonnummer"
      : "Telefoonnummer is verplicht";
    tempErrors.streetName = streetName ? "" : "Straatnaam is verplicht";
    tempErrors.city = city ? "" : "Woonplaats is verplicht";
    tempErrors.postalCode = postalCode
      ? /^\d{4}\s?[a-zA-Z]{2}$/.test(postalCode)
        ? ""
        : "Postcode moet 4 cijfers en 2 letters bevatten"
      : "Postcode is verplicht";
    tempErrors.houseNumber = houseNumber
      ? /^\d+$/.test(houseNumber)
        ? ""
        : "Huisnummer mag alleen nummers bevatten"
      : "Huisnummer is verplicht";
    tempErrors.pickupDate = pickupDate ? "" : "Ophaaldatum is verplicht";
    tempErrors.pickupTime = pickupTime ? "" : "Afhaaltijd is verplicht";
    tempErrors.tosAgreed = tosAgreed
      ? ""
      : "Je moet akkoord gaan met de algemene voorwaarden en privacy policy";

    if (paymentMethod === "contant") {
      console.log("Geheime code opgehaald:", cashCode);
      console.log("Gebruiker ingevoerde code:", userInputCashCode);

      tempErrors.cashCode =
        userInputCashCode === cashCode
          ? ""
          : "De ingevoerde code is onjuist. Neem contact met ons op.";
      tempErrors.cashAccepted = cashAccepted
        ? ""
        : "Je moet akkoord gaan met de voorwaarden";
    }

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleDiscountCodeChange = (event) => {
    setDiscountCode(event.target.value);
  };

  const formatPickupDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleDateString("nl-NL", {
      weekday: "long",
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
  };

  const formatPickupTime = (isoTime) => {
    return isoTime.substring(0, 5);
  };

  const getTimesForDate = (date) => {
    const cartArtNumbers = cart.map((item) =>
      String(item.attributes.artNumber)
    );
    return pickupMoments
      .filter((moment) => formatPickupDate(moment.attributes.date) === date)
      .map((moment) => {
        const currentPickups = moment.attributes.currentPickups || 0;
        const maxPickups = moment.attributes.maxPickups || Infinity;
        const excludedArticles =
          moment.attributes.excludedArtclesFromTimeSlots || [];
        const isExcluded = cartArtNumbers.some((artNumber) =>
          excludedArticles.map(String).includes(artNumber)
        );
        return {
          time: formatPickupTime(moment.attributes.time),
          available: currentPickups < maxPickups && !isExcluded,
        };
      })
      .filter((timeSlot) => timeSlot.available);
  };

  const handleDateChange = (e) => {
    setPickupDate(e.target.value);
    setPickupTime("");
  };

  const handleTimeChange = (e) => {
    setPickupTime(e.target.value);
  };

  const toggleDiscountCodeInput = () => {
    setShowDiscountCodeInput(!showDiscountCodeInput);
  };

  const generateOrderNumber = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const milliseconds = String(now.getMilliseconds())
      .padStart(3, "0")
      .substr(0, 2);
    const randomTwoDigits = String(Math.floor(Math.random() * 100)).padStart(
      2,
      "0"
    );
    return `${day}${milliseconds}${randomTwoDigits}`;
  };

  const handleConfirmOrder = async () => {
    if (isSubmitting) return;

    if (!validate()) return;

    setIsSubmitting(true);
    console.log("Start orderbevestiging...");

    const orderNumber = generateOrderNumber();
    const formattedNotes = notes.replace(/\n/g, "<br>");
    const requestBody = {
      tosAgreed: tosAgreed,
      email: email,
      phoneNumber: phoneNumber,
      userName: `${firstName} ${lastName}`,
      webshopOrderNumber: orderNumber,
      products: cart.map(({ id, count }) => ({ id, count })),
      notes: formattedNotes,
      streetName: streetName,
      houseNumber: houseNumber,
      houseNumberSuffix: houseNumberSuffix || undefined,
      postalCode: postalCode,
      pickupDate: pickupDate,
      pickupTime: pickupTime,
      city: city,
      discountCode: discountCode,
      paymentMethod: paymentMethod,
      paymentStatus: paymentMethod === "contant" ? "contant" : "open",
    };

    console.log("Order payload:", requestBody);

    try {
      const jsonResponse = await fetcher2("webshop-orders", "POST", {
        data: requestBody,
      });

      console.log("Order response:", jsonResponse);

      if (paymentMethod === "contant") {
        navigate(`/checkout/success/${orderNumber}`);
      } else if (jsonResponse && jsonResponse.paymentUrl) {
        window.location.href = jsonResponse.paymentUrl;
      } else {
        console.error("Geen betalingslink ontvangen.");
      }
    } catch (error) {
      console.error("Fout bij bestelling", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    let calculatedTotalPrice = cart.reduce((total, item) => {
      const productPrice = item.attributes.priceDiscount
        ? item.attributes.priceDiscount
        : item.attributes.price;
      return total + item.count * productPrice;
    }, 0);

    setOriginalTotalPrice(parseFloat(calculatedTotalPrice.toFixed(2)));
    if (discount.type === "discountPercentage") {
      calculatedTotalPrice *= 1 - discount.value / 100;
    }

    setTotalPrice(parseFloat(calculatedTotalPrice.toFixed(2)));
  }, [cart, discount]);

  const handleSubmitDiscountCode = async () => {
    try {
      const endpoint = `discount-codes?filters[code]=${discountCode}`;
      const data = await fetcher2(endpoint);

      if (data.data && data.data.length > 0) {
        const discountCodeData = data.data[0].attributes;
        const currentDate = new Date();
        const endDate = new Date(discountCodeData.endDate);

        if (currentDate <= endDate) {
          if (discountCodeData.discountType === "discountPercentage") {
            setDiscount({
              type: "discountPercentage",
              value: discountCodeData.value,
            });
          } else if (discountCodeData.discountType === "freeProduct") {
            // Logic voor gratis product
          }
        } else {
          alert("Kortingscode is verlopen.");
        }
      } else {
        alert("Kortingscode is niet geldig.");
      }
    } catch (error) {
      console.error("Fout bij kortingscodevalidatie", error);
    }
  };

  return (
    <Box width="80%" m="100px auto">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} sx={{ pr: 3 }}>
          <Typography variant="h3" gutterBottom>
            Uw Bestelling
          </Typography>
          <Box>
            <Card variant="outlined" sx={{ mt: 2, ml: 3 }}>
              <CardActions>
                <IconButton
                  onClick={() => setShowOrderDetails(!showOrderDetails)}
                  size="small"
                >
                  {showOrderDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                <Typography component="span">Bestelling Details</Typography>
              </CardActions>
              {showOrderDetails && (
                <CardContent>
                  {cart.map((item) => (
                    <Box key={`${item.attributes.name}-${item.id}`}>
                      <FlexBox p="15px 0">
                        <Box flex="1 1 20%">
                          <img
                            alt={item?.attributes?.name}
                            width="60%"
                            height="auto"
                            src={`${BASE_URL}${
                              item?.attributes?.image?.data?.attributes?.formats
                                ?.medium?.url ||
                              item?.attributes?.image?.data?.attributes?.url
                            }`}
                            style={{ objectFit: "contain" }}
                          />
                        </Box>
                        <Box flex="1 1 80%">
                          <FlexBox mb="5px">
                            <Typography fontWeight="bold">
                              {item.attributes.name}
                            </Typography>
                          </FlexBox>
                          <Typography>
                            {item.attributes.shortDescription}
                          </Typography>
                          <FlexBox m="15px 0">
                            <Typography>{item.count} stuks </Typography>
                            <Typography fontWeight="bold">
                              €
                              {(
                                item.count *
                                (item.attributes.priceDiscount ||
                                  item.attributes.price)
                              ).toFixed(2)}
                            </Typography>
                          </FlexBox>
                        </Box>
                      </FlexBox>
                      <Divider />
                    </Box>
                  ))}
                  <Box display="flex" justifyContent="flex-end" width="100%" />
                </CardContent>
              )}
              <FlexBox justifyContent="space-between" width="100%" p={2}>
                <Typography variant="h6">Totaalprijs:</Typography>
                <Box display="flex" alignItems="center">
                  {discount.type === "discountPercentage" &&
                    originalTotalPrice > totalPrice && (
                      <Typography
                        variant="h6"
                        component="span"
                        sx={{ textDecoration: "line-through", mr: 1 }}
                      >
                        €{originalTotalPrice.toFixed(2)}
                      </Typography>
                    )}
                  <Typography variant="h6" fontWeight="bold">
                    €{totalPrice.toFixed(2)}
                  </Typography>
                </Box>
              </FlexBox>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h3" gutterBottom>
            Uw Gegevens
          </Typography>
          <Box sx={{ pl: 3, pr: 3 }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  error={Boolean(errors.firstName)}
                  name="firstName"
                  autoComplete="given-name"
                  helperText={errors.firstName}
                  label="Voornaam"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={Boolean(errors.lastName)}
                  name="lastName"
                  autoComplete="family-name"
                  helperText={errors.lastName}
                  label="Achternaam"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  error={Boolean(errors.email)}
                  name="email"
                  autoComplete="email"
                  helperText={errors.email}
                  label="E-mail"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={Boolean(errors.phoneNumber)}
                  name="tel"
                  autoComplete="tel"
                  helperText={errors.phoneNumber}
                  label="Telefoonnummer"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  error={Boolean(errors.streetName)}
                  helperText={errors.streetName}
                  name="street-address"
                  autoComplete="street-address"
                  label="Straatnaam"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={streetName}
                  onChange={(e) => setStreetName(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  error={Boolean(errors.houseNumber)}
                  helperText={errors.houseNumber}
                  label="Huisnummer"
                  name="house-number"
                  autoComplete="address-line2"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={houseNumber}
                  onChange={(e) => setHouseNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Toevoeging"
                  name="house-number-suffix"
                  autoComplete="address-line3"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={houseNumberSuffix}
                  onChange={(e) => setHouseNumberSuffix(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  error={Boolean(errors.postalCode)}
                  helperText={errors.postalCode}
                  name="postal-code"
                  autoComplete="postal-code"
                  label="Postcode"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  error={Boolean(errors.city)}
                  helperText={errors.city}
                  name="address-level2"
                  autoComplete="address-level2"
                  label="Woonplaats"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  {pickupMoments.length === 0 ? (
                    <Typography color="error">
                      Geen datums beschikbaar
                    </Typography>
                  ) : (
                    <>
                      <InputLabel>Ophaaldatum</InputLabel>
                      <Select
                        value={pickupDate}
                        label="Ophaaldatum"
                        onChange={handleDateChange}
                        error={Boolean(errors.pickupDate)}
                      >
                        {Array.from(
                          new Set(
                            pickupMoments.map((moment) =>
                              formatPickupDate(moment.attributes.date)
                            )
                          )
                        ).map((date) => (
                          <MenuItem key={date} value={date}>
                            {date}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  {!pickupDate ? (
                    <Select value="" label="" displayEmpty disabled>
                      <MenuItem disabled value="">
                        Selecteer eerst ophaaldatum
                      </MenuItem>
                    </Select>
                  ) : getTimesForDate(pickupDate).length === 0 ? (
                    <Select value="" label="" displayEmpty disabled>
                      <MenuItem disabled value="">
                        Geen tijdsloten beschikbaar
                      </MenuItem>
                    </Select>
                  ) : (
                    <>
                      <InputLabel>Afhaaltijd</InputLabel>
                      <Select
                        value={pickupTime}
                        label="Afhaaltijd"
                        onChange={handleTimeChange}
                        error={Boolean(errors.pickupTime)}
                        displayEmpty
                      >
                        {getTimesForDate(pickupDate).map((timeSlot) => (
                          <MenuItem key={timeSlot.time} value={timeSlot.time}>
                            {timeSlot.time}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <FormControlLabel
              control={
                <Checkbox
                  checked={tosAgreed}
                  onChange={(e) => setTosAgreed(e.target.checked)}
                  name="tosAgreed"
                  color="primary"
                />
              }
              label={
                <Typography>
                  Ik ga akkoord met de{" "}
                  <a
                    href="https://vuurwerkbruchem.com/verkoopvoorwaarden"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    algemene voorwaarden
                  </a>{" "}
                  en{" "}
                  <a
                    href="https://vuurwerkbruchem.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    privacy policy
                  </a>
                  .
                </Typography>
              }
            />
            <TextField
              label="Type hier eventuele bijzonderheden"
              fullWidth
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />

            <FormControl fullWidth margin="normal">
              <InputLabel id="betaalmethode-label">Betaalmethode</InputLabel>
              <Select
                labelId="betaalmethode-label"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                label="Betaalmethode"
              >
                <MenuItem value="online">Online betalen (iDEAL)</MenuItem>
                <MenuItem value="contant">
                  Contant betalen (bij afhalen)
                </MenuItem>
              </Select>
            </FormControl>

            {paymentMethod === "online" && (
              <>
                <Button onClick={toggleDiscountCodeInput}>
                  Klik hier om een kortingscode te verzilveren
                </Button>
                {showDiscountCodeInput && (
                  <TextField
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="Kortingscode"
                    value={discountCode}
                    onChange={handleDiscountCodeChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleSubmitDiscountCode}>
                            <CheckCircleIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </>
            )}

            {paymentMethod === "contant" && (
              <>
                <Box
                  sx={{
                    backgroundColor: "#ffcccb",
                    border: "1px solid #f44336",
                    padding: 2,
                    borderRadius: 2,
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <Typography variant="body1">
                    <strong>Let op:</strong> Als je kiest voor contante
                    betaling, ga je een betalingsverplichting aan. Neem contact
                    op met ons via <strong>0418-700511</strong> om je bestelling
                    te bevestigen. Bij dit contact ontvang je een code die je
                    hieronder moet invullen om de bestelling definitief te
                    maken. De betaling kan contant of per pin worden voldaan bij
                    het afhalen van de bestelling.
                  </Typography>
                </Box>

                <TextField
                  label="Telefonische Code"
                  value={userInputCashCode}
                  onChange={(e) => setUserInputCashCode(e.target.value)}
                  fullWidth
                  margin="normal"
                  error={Boolean(errors.cashCode)}
                  helperText={
                    errors.cashCode ||
                    "Vul hier de code in die je telefonisch hebt ontvangen."
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cashAccepted}
                      onChange={(e) => setCashAccepted(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Ik ga akkoord en begrijp dat ik een betalingsverplichting aanga."
                  sx={{ mt: 2 }}
                />

                {Boolean(errors.cashAccepted) && (
                  <Typography color="error" sx={{ mt: 1 }}>
                    {errors.cashAccepted}
                  </Typography>
                )}
              </>
            )}

            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                disabled={
                  isSubmitting ||
                  cart.length === 0 ||
                  (paymentMethod === "contant" && !cashAccepted)
                }
                onClick={handleConfirmOrder}
              >
                {paymentMethod === "online"
                  ? "Naar Betalen"
                  : "Bestelling Plaatsen"}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Checkout2;
