import { BASE_URL } from "../api";
import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";

const ChangePasswordModal = ({ isOpen, onClose, userData }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChangePassword = async () => {
    setIsLoading(true);
    setError(null);

    // 1. Verifieer het huidige wachtwoord
    const loginUrl = `${BASE_URL}/api/auth/local`;
    try {
      const loginResponse = await fetch(loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          identifier: userData.email, // of userData.username, afhankelijk van je setup
          password: currentPassword,
        }),
      });

      const loginData = await loginResponse.json();

      if (!loginResponse.ok) {
        throw new Error(loginData.message || "Huidig wachtwoord is onjuist.");
      }

      // 2. Wijzig het wachtwoord van de gebruiker
      const url = `${BASE_URL}/api/users/${userData.id}`;
      const token = localStorage.getItem("jwt");

      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          password: newPassword,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(
          data.message ||
            "Er ging iets mis bij het wijzigen van het wachtwoord."
        );
      }

      alert("Wachtwoord succesvol gewijzigd!");
      onClose();
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Wachtwoord wijzigen</DialogTitle>
      <DialogContent>
        {error && <div style={{ color: "red" }}>{error}</div>}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="currentPassword"
          label="Huidig wachtwoord"
          type="password"
          id="currentPassword"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="newPassword"
          label="Nieuw wachtwoord"
          type="password"
          id="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Annuleren
        </Button>
        <Button
          onClick={handleChangePassword}
          color="primary"
          disabled={isLoading}
        >
          Wijzigen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordModal;
