import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="shift-on-search" style={{ width: "80%", margin: "auto", marginTop: "80px" }}>
      <h1>Privacyverklaring</h1>
      
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h2>Inleiding</h2>
        <p>Wij respecteren de privacy van onze klanten en bezoekers van onze website en zorgen ervoor dat de persoonlijke informatie die u ons verschaft vertrouwelijk wordt behandeld.</p>

        <h2>Welke gegevens verzamelen we?</h2>
        <p>We verzamelen enkel informatie die noodzakelijk is voor de werking van onze website en de verkoop van producten. Dit omvat:</p>
        <ul>
          <li>Contactgegevens zoals naam, e-mailadres en telefoonnummer</li>
          <li>Bestelgegevens</li>
          <li>Betalingsinformatie</li>
          <li>Gegevens over uw gebruik van onze website (via cookies)</li>
        </ul>

        <h2>Hoe gebruiken we deze gegevens?</h2>
        <p>De verzamelde gegevens worden uitsluitend gebruikt voor relevante activiteiten gerelateerd aan de werking van onze website en verkoopgerelateerde activiteiten.</p>

        <h2>Cookiegebruik</h2>
        <p>Onze website maakt gebruik van cookies om uw ervaring te verbeteren en om statistieken over het gebruik van onze website te verzamelen. U kunt kiezen welke cookies u toestaat via onze CookieWall.</p>

        <h2>Bewaartermijn</h2>
        <p>We bewaren uw gegevens volgens de richtlijnen van de wet en zorgen voor een adequate beveiliging.</p>

        <h2>Uw rechten</h2>
        <p>U heeft het recht om uw gegevens in te zien, te corrigeren, te verwijderen of bezwaar te maken tegen de verwerking ervan. Neem contact met ons op als u gebruik wilt maken van deze rechten.</p>

        <h2>Contact</h2>
        <p>Als u vragen of opmerkingen heeft over onze privacyverklaring, neem dan contact met ons op via [uw contactgegevens].</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
